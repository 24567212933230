<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>FCH Applications</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Applications"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>

        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: 'module-lhl-applications-fch-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Applications</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="tableHeaders"
            :items="applications"
            no-data-text="No applications"
            :items-per-page="-1"
          >
            <template v-slot:item.size="{ item }">
              1st: {{ item.size_first_choice }}

              <div v-if="item.size_second_choice">
                2st: {{ item.size_second_choice }}
              </div>
              <div v-if="item.size_third_choice">
                3st: {{ item.size_third_choice }}
              </div>
            </template>

            <template v-slot:item.litter="{ item }">
              <div v-if="item.admins.litter_id">
                {{ item.admins.litter.name }}
              </div>
            </template>

            <template v-slot:item.created_at="{ item }">
              {{ formatDateTime(item.created_at, true) }}
            </template>

            <template v-slot:item.colours="{ item }">
              {{ displayColors(item) }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn
                x-small
                depressed
                class="mr-2"
                color="green lighten-4 green--text"
                :to="{
                  name: 'module-lhl-applications-fch-individual',
                  params: { applicationId: item.id },
                }"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>

              <v-btn
                bottom
                x-small
                depressed
                color="red lighten-4 red--text"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-dialog
        scrollable
        v-model="deleteApplication.dialog"
        persistent
        max-width="400"
      >
        <v-card>
          <v-card-title class="headline">Archive Application</v-card-title>
          <v-card-text>Are you sure you wish to archive?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetDelete"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="deleteApplication.loading"
              @click="saveDelete"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
//import ApplicationForm from "../components/ApplicationForm";

export default {
  data() {
    return {
      searchTerm: "",
      deleteApplication: {
        dialog: false,
        dog: {},
        loading: false,
      },
      tableHeaders: [
        { text: "Id", value: "admins.title" },
        { text: "Name", value: "admins.full_name" },
        { text: "Litter ID", value: "litter" },
        { text: "Colour", value: "colours" },
        { text: "Size", value: "size" },

        { text: "Date", value: "created_at" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],

      colors: [
        { text: "Black/Silver/Blue", value: "black_silver_blue" },
        {
          text: "Chocolate/Café/Lavender/Parchment",
          value: "chocolate_cafe_lavender_parchment",
        },
        { text: "Red/Gold", value: "red_gold" },
        { text: "Gold/Cream", value: "gold_cream" },
        { text: "Caramel", value: "caramel" },
        { text: "Black Parti", value: "black_parti" },
        { text: "Chocolate Parti", value: "chocolate_parti" },
      ],

      deleteOrder: {
        dialog: false,
        order: {},
        loading: false,
      },
    };
  },

  components: {
    // ApplicationForm,
  },

  methods: {
    openDelete(application) {
      this.deleteApplication.application = application;
      this.deleteApplication.dialog = true;
    },

    resetDelete() {
      this.deleteApplication.dialog = false;
      this.deleteApplication.application = {};
      this.deleteApplication.loading = false;
    },

    saveDelete() {
      this.deleteApplication.loading = true;

      this.$store
        .dispatch("lhl/applications/deleteApplicationFch", {
          appId: this.$route.params.id,
          applicationId: this.deleteApplication.application.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteApplication.loading = false;
        });
    },
    cleanArray: function (actual) {
      var newArray = new Array();
      for (var i = 0; i < actual.length; i++) {
        if (actual[i]) {
          newArray.push(actual[i]);
        }
      }
      return newArray;
    },

    displayColors(application) {
      let colors = [];

      this.colors.forEach((color) => {
        if (application[color.value]) {
          colors[application[color.value]] = color.text;
        }
      });

      colors = this.cleanArray(colors);
      if (colors.length > 0) {
        return colors.join(", ");
      } else {
        return "none";
      }
    },
  },

  computed: {
    applications() {
      let applications = this.$store.getters["lhl/applications/fch"];

      // if (this.searchTerm !== "") {
      //   applications = applications.filter((c) => {
      //     const name = c.name.toLowerCase();

      //     const searchTerm = this.searchTerm.toLowerCase();

      //     return name.includes(searchTerm);
      //   });
      // }

      return applications;
    },
  },
};
</script>
